<template>
  <div>
    <MyHeader />
    <main id="content">
      <ProfileMenu />
      <div class="container mt-4 mt-lg-5">
        <div action=""
             id="editProfile">
          <form class="row justify-content-between align-items-center"
                @submit.prevent="validateForm('avatarForm')"
                data-vv-scope="avatarForm">
            <div class="col pr-0 pr-md-3 col-md-auto col-lg-6">
              <div class="account-profile">
                <div class="pic">
                  <img ref="image"
                       :src="user.avatar && user.avatar.path ? user.avatar.path : '/img/user-thumb.jpg'">
                </div>
                <div class="info">
                  <h1>{{ user.name }} {{ user.surname }}</h1>
                  <label for="profilePic"
                         class="btn btn-sm"
                         @click="avatarController = !avatarController"><i class="icon-edit" /> Upload new photo</label>
                  <!-- <input type="file" id="profilePic" hidden @change="onAvatarSelected"> -->
                </div>
              </div>
            </div>
            <div class="col col-md-auto col-lg-6">
              <div class="account-profile">
                <div class="info">
                  <vue-avatar v-if="avatarController"
                              name="avatar"
                              class="text-center"
                              :width="400"
                              :height="400"
                              radius="200"
                              ref="vueavatar" />
                  <button v-if="avatarController"
                          @click.prevent="onAvatarSelected"
                          class="btns btn btn-block">
                    Crop
                  </button>
                </div>
              </div>
            </div>
          </form>
          <hr>
          <div class="row justify-content-between">
            <form class="col-12 col-lg-6 mb-4"
                  @submit.prevent="validateForm('profileForm')"
                  data-vv-scope="profileForm">
              <div class="form-group">
                <label>Name:</label>
                <input type="text"
                       name="name"
                       v-model="user.name"
                       v-validate="'required'"
                       class="form-control"
                       placeholder="Paul">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('profileForm.name')">{{ errors.first('profileForm.name') }}</span>
              <div class="form-group">
                <label>Surname:</label>
                <input type="text"
                       name="surname"
                       v-model="user.surname"
                       v-validate="'required'"
                       class="form-control"
                       placeholder="Jimenez Rojas">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('profileForm.surname')">{{ errors.first('profileForm.surname') }}</span>
              <div class="form-group">
                <label>Email Address:</label>
                <input type="text"
                       name="email"
                       v-model="user.email"
                       v-validate="'required|email'"
                       class="form-control"
                       placeholder="pauljmnz@gmail.com">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('profileForm.email')">{{ errors.first('profileForm.email') }}</span>
              <div class="form-group">
                <label>Area:</label>
                <div class="input-group">
                  <vue-tel-input v-model="user.phone"
                                 @country-changed="countryChanged"
                                 :default-country="user.phoneCode"
                                 class="form-control" />
                </div>
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('profileForm.phone')">{{ errors.first('profileForm.phone') }}</span>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('profileForm.phoneCode')">{{ errors.first('profileForm.phoneCode') }}</span>
              <div class="row">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>Date of Birth:</label>
                  </div>
                </div>
                <div class="col-auto pr-0">
                  <div class="form-group">
                    <select class="form-control"
                            name="day"
                            v-validate="'required'"
                            v-model="user.birthday.day">
                      <option value="1">
                        1
                      </option>
                      <option value="2">
                        2
                      </option>
                      <option value="3">
                        3
                      </option>
                      <option value="4">
                        4
                      </option>
                      <option value="5">
                        5
                      </option>
                      <option value="6">
                        6
                      </option>
                      <option value="7">
                        7
                      </option>
                      <option value="8">
                        8
                      </option>
                      <option value="9">
                        9
                      </option>
                      <option value="10">
                        10
                      </option>
                      <option value="11">
                        11
                      </option>
                      <option value="12">
                        12
                      </option>
                      <option value="13">
                        13
                      </option>
                      <option value="14">
                        14
                      </option>
                      <option value="15">
                        15
                      </option>
                      <option value="16">
                        16
                      </option>
                      <option value="17">
                        17
                      </option>
                      <option value="18">
                        18
                      </option>
                      <option value="19">
                        19
                      </option>
                      <option value="20">
                        20
                      </option>
                      <option value="21">
                        21
                      </option>
                      <option value="22">
                        22
                      </option>
                      <option value="23">
                        23
                      </option>
                      <option value="24">
                        24
                      </option>
                      <option value="25">
                        25
                      </option>
                      <option value="26">
                        26
                      </option>
                      <option value="27">
                        27
                      </option>
                      <option value="28">
                        28
                      </option>
                      <option value="29">
                        29
                      </option>
                      <option value="30">
                        30
                      </option>
                      <option value="31">
                        31
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-auto px-2">
                  <div class="form-group">
                    <select class="form-control"
                            name="month"
                            v-validate="'required'"
                            v-model="user.birthday.month">
                      <option value="1">
                        January
                      </option>
                      <option value="2">
                        February
                      </option>
                      <option value="3">
                        March
                      </option>
                      <option value="4">
                        April
                      </option>
                      <option value="5">
                        May
                      </option>
                      <option value="6">
                        June
                      </option>
                      <option value="7">
                        July
                      </option>
                      <option value="8">
                        August
                      </option>
                      <option value="9">
                        September
                      </option>
                      <option value="10">
                        October
                      </option>
                      <option value="11">
                        November
                      </option>
                      <option value="12">
                        December
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-auto pl-0">
                  <div class="form-group">
                    <select class="form-control"
                            name="year"
                            v-validate="'required'"
                            v-model="user.birthday.year">
                      <option value="1992">
                        1992
                      </option>
                      <option value="1991">
                        1991
                      </option>
                      <option value="1990">
                        1990
                      </option>
                      <option value="1989">
                        1989
                      </option>
                      <option value="1988">
                        1988
                      </option>
                      <option value="1987">
                        1987
                      </option>
                      <option value="1986">
                        1986
                      </option>
                      <option value="1985">
                        1985
                      </option>
                      <option value="1984">
                        1984
                      </option>
                      <option value="1983">
                        1983
                      </option>
                      <option value="1982">
                        1982
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Gender:</label>
                <select class="form-control"
                        name="gender"
                        v-validate="'required'"
                        v-model="user.gender">
                  <option value="1"
                          disabled
                          selected>
                    Select a gender
                  </option>
                  <option value="Male">
                    Male
                  </option>
                  <option value="Female">
                    Female
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label>Country:</label>
                <select class="form-control"
                        name="country"
                        v-validate="'required'"
                        v-model="user.country">
                  <option value="1"
                          disabled
                          selected>
                    Select a country
                  </option>
                  <option v-for="(country, index) in countries"
                          :key="index"
                          :value="country.id">
                    {{ country.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label>School:</label>
                <input type="text"
                       class="form-control"
                       name="school"
                       v-validate="'required'"
                       v-model="user.school"
                       placeholder="UNAPEC">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('profileForm.school')">{{ errors.first('profileForm.school') }}</span>
              <div class="form-group">
                <label>Work:</label>
                <input type="text"
                       class="form-control"
                       name="work"
                       v-validate="'required'"
                       v-model="user.work"
                       placeholder="KATION INTERACTIVE">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('profileForm.work')">{{ errors.first('profileForm.work') }}</span>
              <div class="row justify-content-end">
                <div class="alert alert-success"
                     role="alert"
                     v-if="isUpdated">
                  Profile updated!
                </div>
                <div class="col-12 col-md-4">
                  <button class="btn btn-lg btn-primary btn-block">
                    SAVE
                  </button>
                </div>
              </div>
            </form>
            <div class="col justify-content-center d-none d-lg-flex">
              <div class="h-100 border-left" />
            </div>
            <div class="col-12 col-lg-5">
              <form @submit.prevent="validateForm('passwordForm')"
                    data-vv-scope="passwordForm">
                <h5>Modify Password</h5>
                <hr>
                <div class="row">
                  <div class="col-12 mb-5">
                    <div class="form-group has-icon">
                      <i class="icon-lock" />
                      <input id="currentPassword"
                             type="password"
                             class="form-control"
                             name="oldPassword"
                             v-validate="'required'"
                             v-model="user.oldPassword"
                             placeholder="Old password">
                    </div>
                    <span class="text-danger small mb-3 d-block fix-margin"
                          v-if="errors.has('passwordForm.oldPassword')">{{ errors.first('passwordForm.oldPassword') }}</span>
                    <div class="form-group has-icon">
                      <i class="icon-lock" />
                      <button type="button"
                              class="showPass"
                              @click="switchVisibility">
                        {{ user.passwordFieldType == 'password' ? 'Show' : 'Hide' }}
                      </button>
                      <input class="form-control"
                             name="newPassword"
                             :type="user.passwordFieldType"
                             v-validate="'required'"
                             v-model="user.newPassword"
                             placeholder="New password">
                    </div>
                    <span class="text-danger small mb-3 d-block fix-margin"
                          v-if="errors.has('passwordForm.newPassword')">{{ errors.first('passwordForm.newPassword') }}</span>
                    <div class="alert alert-success"
                         role="alert"
                         v-if="isUpdatedPassword">
                      Password updated!
                    </div>
                    <button class="btn btn-sm btn-primary">
                      MODIFY
                    </button>
                  </div>
                </div>
              </form>
              <form id="userNotificationsForm">
                <h5>Email Notifications</h5>
                <hr>
                <div class="custom-control custom-checkbox mb-2"
                     v-for="(field, index) in user.notificationsFields"
                     :key="index">
                  <input type="checkbox"
                         class="custom-control-input"
                         :id="`notificationsField${field.id}`"
                         :value="field.id"
                         v-model="user.customNotifications"
                         @change="updateUserNotifications(field.id)">
                  <label class="custom-control-label"
                         :for="`notificationsField${field.id}`">{{ field.name }}</label>
                </div>
                <!-- v-model="user.notifications.conciergeMessage" -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <ContactOurExperts />
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { VueAvatar } from 'vue-avatar-editor-improved';
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import ContactOurExperts from '../../components/users/ContactOurExperts.vue';


export default {
  name: 'UserProfile',
  components: {
    ProfileMenu,
    ContactOurExperts,
    VueAvatar,
  },
  data() {
    return {
      isUpdated: false,
      isUpdatedPassword: false,
      avatarController: false,
      countries: {},
      user: {
        avatar: {
          path: null,
        },
        name: null,
        surname: null,
        email: null,
        phoneCode: null,
        phone: null,
        birthday: {
          day: '1',
          month: '1',
          year: '1992',
        },
        gender: null,
        country: null,
        school: null,
        work: null,
        oldPassword: null,
        newPassword: null,
        passwordFieldType: 'password',
        customNotifications: [],
        notifications: {},
        notificationsFields: {},
      },
    };
  },
  created() {
    const localStorageUser = this.getUser();
    this.user.name = localStorageUser.name;
    this.user.surname = localStorageUser.surname;
    this.user.email = localStorageUser.email;
    // this.user.phone = localStorageUser.phoneCode ? localStorageUser.phoneCode : 1;
    this.user.phone = localStorageUser.phone;
    this.user.gender = localStorageUser.gender ? localStorageUser.gender : 1;
    this.user.school = localStorageUser.school;
    this.user.work = localStorageUser.company;
    this.user.phoneCode = localStorageUser.area_code;
    this.user.notifications = localStorageUser.notifications;
    if (this.user.notifications && this.user.notifications.length > 0) {
      this.user.notifications.forEach((element) => {
        this.user.customNotifications.push(element.notification_id);
      });
    }
    if (localStorageUser.birthday) {
      const birthday = localStorageUser.birthday.split('/');
      this.user.birthday = {
        day: birthday[2],
        month: birthday[1],
        year: birthday[0],
      };
    }
    if (localStorageUser.avatar) {
      this.user.avatar = localStorageUser.avatar;
    }
    this.user.country = localStorageUser.country ? localStorageUser.country.id : 1;
    this.$store.dispatch('requestCountries').then((response) => {
      this.countries = response;
    });
    this.$store.dispatch('requestNotificationsFields').then((response) => {
      this.user.notificationsFields = response;
    });
  },
  methods: {
    ...mapGetters([
      'getUser',
    ]),
    countryChanged(country) {
      this.user.phoneCode = country.iso2;
    },
    validateForm(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          if (scope === 'passwordForm') {
            const password = {
              oldPassword: this.user.oldPassword,
              newPassword: this.user.newPassword,
            };
            this.$store.dispatch('updateUserPassword', password).then(() => {
              this.isUpdatedPassword = true;
            });
          }
          if (scope === 'profileForm') {
            this.$store.dispatch('updateUserProfile', this.user).then(() => {
              this.isUpdated = true;
            });
          }
        }
      });
    },
    updateUserNotifications(notificationID) {
      const formData = new FormData();
      formData.append('user_id', this.getUser().id);
      formData.append('notification_id', notificationID);
      this.$axios.post('/v1/contactcenter/notifications', formData).then((response) => {
        this.$store.commit('setUser', response.data.data);
      });
    },
    onAvatarSelected() {
      const img = this.$refs.vueavatar.getImageScaled();
      this.user.avatar.path = img.toDataURL();
      this.avatarController = !this.avatarController;
    },
    switchVisibility() {
      this.user.passwordFieldType = this.user.passwordFieldType === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style scoped>
.btns {
  box-shadow: none!important;
  background-color: transparent!important;
}
</style>
